
.padding-bottom-20 {
    padding-bottom: 20px!important;
}
.padding-top-40 {
    padding-top: 40px!important;
}
.margin-top-20{
    margin-top: 20px;
}
.margin-top-10{
    margin-top: 20px;
}
.margin-bottom-10{
    margin-bottom: 10px;
}
@media (min-width: 1025px){
.navbar.bg-dark {
    background: rgba(0, 0, 0, 0.7)!important;
    padding:20px 100px;
    color:#ffffff!important;
    
}

.carousel {
    margin-top: -100px;
}
.navbar-nav{
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;
    margin-right: 130px;
}
}
.navbar-dark .navbar-nav .nav-link {
    color: rgba(255,255,255,1)!important;
    text-transform: uppercase!important;
}
.navbar-dark:hover .navbar-nav:hover .nav-link:hover {
    text-shadow: 2px 2px 10px #FFFFFF;
}
.App {
  text-align: left;
}

.App-logo {
  /*height: 40vmin;*/
  width:  200px;
  pointer-events: none;
}
.Checkout-logo {
  width:  125px;
  pointer-events: none;
}
/*showcase*/
.showcase{
    margin-top:50px!important;
}
.showcase a{
    text-decoration: none!important;
    color:#FFFFFF!important;
}
.showcase a:hover{
    text-shadow: 2px 2px 10px #FFFFFF;
}
.showcase .col-6{
    margin-bottom:50px;
}
/*video*/
.video{
    margin-bottom: 50px!important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



/*Custom.css*/
html, body {
    height: 100%;
  }
  .nav-item a:after {
  content: "/";
  padding-left: 10px;
}
 .nav-item:last-child a:after {
  content: none;
}

.nav-link:focus{
    color:red!important;
}
.sticky-top{
    position: relative!important;
}
.white{
    color:#ffffff;
}
.orange{
    color:#ff3100;
}
.dark{
    color:#212529;
}
.whitespace{
margin-top:50px!important;
}
.left{
    text-align: left;
}
.title{
    margin-bottom:20px!important;
}
.showcase{
    margin-top:50px;
}
.MainContainerImg{
    background-image: url(https://ajsliders.com/images/tagline_bg4.png);
    background-attachment: fixed;
     -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.HeaderImg{
    background: url('./assets/images/about_us_bg.png') no-repeat center center!important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 100px 0 20px 0;
  margin-top: -100px;

  
}
.CookieConsent{
    text-align: center!important;
}
.center{
    margin:0 auto;
    text-align: center!important;
    
}
.MainContainerImg{
    padding-bottom: 50px;
}
.MainContainerImg h1{
    color: #FFFFFF!important;
    margin:20px 0 20px 0;
}
.MainContainerImg a{
    text-decoration: none;
    color: whitesmoke !important;
}
.MainContainerImg a:hover{
    color:rgba(f, f, f, 0.87)!important;
}

.MainContainerImg .list-group-item{
    background-color: transparent;
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
}
.MainContainerImg button{
  margin: 0!important;
}
/*footer*/
.footer-area{
     background-image: url('./assets/images/footer-bg.jpg');
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff!important;
    background-attachment: fixed;
}
.footer-area .f-logo{
    padding-bottom: 20px;
}
.footer-area .widget{
    text-align: left!important;
    padding: 10px 0;
}
.footer-area .widget a{
    color: #ff9933;
    text-transform: capitalize;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    text-decoration: none;
}
.footer-area .widget a:hover{
    color: #ffffff; 
}
.footer-area .f-title span{
     color: #ff9933;
}
.footer{
    margin-top:20px;
    padding-bottom: 20px;
    text-align: center!important;
    
}
.footer .nav{
    margin-bottom: 10px;
}
.footer .nav-link{
    padding: 0.5rem 0.5rem;
}
.footer .privacy a{
    color: #ababab;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;

}
.heart span{
    color:red;
}
.footer .privacy a:hover{
    color: #ffffff;
}
.footer p{
    font-size: 12px;
    color: #5e5e5e;
}
.footer svg{
    color: tomato;
}
.heart svg{
    color: tomato;
}
@media (max-width:979px){
    .col-6{
        width: 100%!important;
        margin-top:50px;
    }
    .showcase{
        margin-top:0;
    }
    .img-sm{
        width: 90%;
    }
    .video{
        width: 100%!important;
    }
   .dept-btn-cont{
       width: 80%;
   }
   .img-icon{
    display: none;
   }
   .showcase .col-6{
    margin-bottom: 10px!important;
   }
   .about-info .col-8{
    width: 100%!important;
   }
   .inner-about-gallery .float-left{
   float: none;
  width: 100%;
  margin: 0 auto;
   }
   .inner-about-gallery .float-left img{
  width: 80%;
  margin-top:10px;
   }
}
    
@media  (min-width:979px){
 .topbtn{
    display:block;
    color: white;
    text-align:center;
    position: absolute;
    top: 39px!important;
    right: 25px!important;
    font-size: 0.875rem;
 }
}

.MobileContImg{
    background: url('./assets/images/background.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: '100vw';
    height: '100vh';
    padding:35px 0;
    text-align: center !important;
}
.MobileContImg a{
    text-decoration: none;
}
.our-dept-main {
    width: 100%;
    height: auto;
    text-align: center;
    position: relative;
    transition: 0.5s;
    margin-bottom: 15px;
    background: transparent;
    color: whitesmoke;
}
.dept-front {
    /* background: #fff; */
    width: 100%;
    height: auto;
    position: relative;
    z-index: 10;
    bottom: 0px;
    transition: all 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    max-width: 420px;
    padding: 15px 10px;
    margin: auto;
    box-shadow: 0 0 15px rgb(0 0 0);
}
.dept-front h3 {
    margin-top: 10px!important;
    margin-top: 10px!important;
    min-width: 183px;
    text-align: left;
    margin-left: 20px;

}

.dept-1 {
    background: linear-gradient(
36deg, rgba(18,66,40,1) 0%, rgba(16,124,65,1) 50%, rgba(51,196,129,1) 100%);
}
.dept-2 {
    background: linear-gradient(
36deg, rgba(0,93,232,1) 0%, rgba(44,138,254,1) 50%, rgba(63,169,245,1) 100%);
}
.dept-3 {
    background: linear-gradient(
36deg, rgba(136,33,168,1) 0%, rgba(137,70,202,1) 50%, rgba(111,112,252,1) 100%);
}
.dept-btn-cont{
    margin:  50px auto !important;
}

/*social connect*/
.social-connect{
    background: rgb(255, 49, 0);
    padding:50px 0;
    color:#ffffff;
    text-align: center;
    text-transform: uppercase;
}
.social-title{
    margin: 0 auto;
}
.social-icons ul{
    margin-top:30px;
}
.social-icons ul, li{
    list-style-type:none;
    padding: 0 5px;
}
.social-icons li{
    vertical-align: middle;
    line-height: 1em;
    display: inline-block !important;
}
.social-icons a{
    padding: 10px 14px;
    color: rgb(255, 49, 0);
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 100%;
    font-size: 24px;
}
.social-icons li:hover{
    animation: pulse 2s infinite;
}
.social-icons a:hover{
    color: rgb(255, 49, 0);
}

@keyframes pulse {
0%,
    100% {
      animation-timing-function: ease-in;
    }
    50% {
      transform: scale(1.2);
    }
  }

.HeaderImg.row{
    margin-top:60px;
}
/*discover menu*/
.card-group> div{
    margin: 10px!important;
}
.card-group{
    margin-bottom: 50px;
}
.rightImg{
  background: url('./assets/images/team-img-right.png') no-repeat center center;
  -webkit-background-size: auto 100%;
  -moz-background-size: auto 100%;
  -o-background-size: auto 100%;
  background-size: auto 100%;
  max-width: 100%;
}
.TeamHeader{
    background: #485563;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #29323c, #485563);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #29323c, #485563); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 100px 0 20px 0;
    margin-top: -100px;
}
/* Default height for small devices */
.TeamHeader {
  height: 600px;
}
.MuiGridList-root::-webkit-scrollbar {
    width: 0!important;  /* Remove scrollbar space */
    background: transparent!important;  /* Optional: just make scrollbar invisible */
}
/*button*/
.btn-red {
  background: #cc3333!important;
  padding: 12px 20px!important;
  color: #ffffff!important;
  font-size: 18px!important;
  font-weight: 500!important;
  text-transform: capitalize!important;
  display: inline-block!important;
  border: 1px solid transparent!important;
  border-radius: 7px!important;
  -webkit-transition: 0.3s!important;
  -o-transition: 0.3s!important;
  transition: 0.3s!important; }
  .btn-red:focus {
    outline: none!important; }
  .btn-red:focus-visible {
    outline: none!important; }
  .btn-red:hover {
    background: transparent!important;
    border: 1px solid #cc3333!important;
    color: #cc3333!important; }



.left{
    text-align: left;
}
.showcase h1{
    font-weight: 900!important;
    text-align: left;
}
.showcase h4, .showcase h3{
    font-weight: 700;
}
.showcase h4 a{
    color:unset!important;
}
.showcase h4 a:hover{
    color:#cc3333!important;
}
.showcase-right-text{
    margin-top:20px;
    background: #ffffff;
      -webkit-box-shadow: 0px 5px 68px 0px rgba(0, 21, 100, 0.1);
      box-shadow: 0px 5px 68px 0px rgba(0, 21, 100, 0.1);
      padding: 35px 35px 0 35px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 35px; 
}
.showcase .img-icon{
    margin-right: 20px;
    text-align: center;
}
.gallery span:hover{
    cursor: default!important;
}
/*about*/
 .about-info-left {
    position: relative;
    background: #cc3333;
    border-radius: 5px;
    padding: 35px 25px 21px 30px;
    color: #ffffff;
    margin-top: -13%; }
.about-info-right {
    padding: 50px 40px;
}
.inner-about-gallery .float-left{
   float: left;
  margin-right: 20px!important;
}
.inner-about-gallery{
  text-align: center;
}
.about.video{
  position: absolute;
    top: 0;
    left: 0;
    width: 100%!important;
    height: 100%!important;
    margin-top: 10px;
}
.full-width{
    position: relative!important;
    width: 100%!important;
    height: 0!important;
    padding-bottom: 56.25%!important;
    margin-bottom: 10px;
}
.ContactHeader{
    background: url('./assets/images/ContactSliderImg.png') no-repeat center center!important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
    padding: 100px 0 20px 0;
    margin-top: -100px;
}
.ContactHeader {
  height: 600px;
}
@media (min-width: 992px) {
      .ContactHeader {
        height: 625px;
      }
}
.PhonerightImg{
  background: url('./assets/images/PhoneImg.png') no-repeat center center;
  -webkit-background-size: auto 100%;
  -moz-background-size: auto 100%;
  -o-background-size: auto 100%;
  background-size: auto 100%;
  max-width: 100%;
}
.blankspace{
margin-top:15px!important;
}
.MuiButtonBase-root.MuiButton-root {
    background-color: #354340 !important;
}
.MuiButtonBase-root.MuiButton-root:active {
    background-color: #354340 !important;
}
.MuiButtonBase-root.MuiButton-root:focus {
    background-color: #354340 !important;
}
.MuiButtonBase-root.MuiButton-root:focus-visible {
    background-color: #354340 !important;
}
.MuiButtonBase-root.MuiButton-root:hover {
    background-color: #354340 !important;
    border: 1px solid #354340!important;
    color: #fff !important;
}
.DailySpec{
background: url('./assets/images/DailySpec.png') no-repeat center center!important;
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
padding: 100px 0 20px 0;
margin-top: -100px;

}
img{
max-width: 100%;
width: auto\9;
height: auto;
vertical-align: middle;
border: 0;
-ms-interpolation-mode: bicubic;
}
.float-right{
float: right;
}
.bold{
font-weight: 900;
}
.MuiButton-root {
    color: rgba(250, 250, 250, 0.97) !important;
    border-radius: 3px !important;
    font-size: 1rem;
}

.MuiButtonBase-root.MuiButton-root {
    margin-left: auto;
    margin-right: auto;
}

.MuiButton-text {
    padding: 6px 8px;
}
.MuiButton-fullWidth {
    padding: 1rem .125rem !important;
}
.OrderHeader{
    background: url('./assets/images/ContactSliderImg.png') no-repeat center center!important;
    background: #485563;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #29323c, #485563);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #29323c, #485563); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 100px 0 20px 0;
    margin-top: -130px;
}
/* Default height for small devices */
.OrderHeader {
  height: 600px;
}
@media (min-width: 992px) {
      .OrderHeader {
        height: 525px;
      }
}
@media (max-width: 991px) {
    .OrderHeader .card {
        display: none;
    }
}
/* .MuiTypography-h4 {
    font-size: 2rem !important;
} */
.item-container {
    display: inline-grid !important;
    grid-template-columns: auto 1fr !important;
  }
  @media (max-width: 970px) {
    .item-container {
      display: unset !important;
      grid-template-columns: unset !important;
    }
  }
  @media (min-width: 320px) and (max-width: 500px) {
    #checkoutTable .checkcell01 {
      display: none !important;
    }
  }
  @media (min-width: 320px) and (max-width: 500px) {
    #checkoutTable .checkcell02 {
      padding: 5px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 500px) {
    #checkoutTable .checkcell03 {
      padding: 5px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 500px) {
    #checkoutTable .checkcell04 {
      padding: 5px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 500px) {
    .couponcode {
      min-width: 180px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 500px) {
    #checkoutTotal .totalcell03 {
      display: none !important;
    }
  }
  @media (min-width: 320px) and (max-width: 500px) {
    #checkoutTotal .totalcell02 {
      padding: 5px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 500px) {
    #checkoutTotal .totalcell01 {
      padding: 5px !important;
      width: 150px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 500px) {
    #checkoutTotal .totalcell04 {
      padding: 5px !important;
    }
  }
  #checkoutTotal .MuiButton-root {
    width: 80px !important;
  }
.MuiOutlinedInput-input {
    padding: 14px 14px !important;
}
.modal-container {
  overflow-y: auto;
}

.pulse {
  width: 100%;
  height: 100%;
  background-color: #42b983;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: pulse2 2s infinite;
}

@keyframes pulse2 {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
}

.dot-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot-loader .dot {
  width: 5px;
  height: 5px;
  background-color: #ffff;
  border-radius: 100%;
  display: inline-block;
  margin: 0 3px;
  animation: bounce 1.8s infinite ease-in-out;
}

.dot-loader .dot:nth-child(2) {
  animation-delay: -0.32s;
}

.dot-loader .dot:nth-child(3) {
  animation-delay: -0.16s;
}

.MuiOutlinedInput-notchedOutline {
  border: 0 !important;
}
